import * as React from 'react';
import clsx from 'clsx';
import { ColDef, CellClassParams, CellParams } from '@material-ui/data-grid';

function buildCellClassParams(params: CellClassParams) {
  return clsx('styled-grid', {
    inProgress: params.row.actionInProgress,
  });
}

const getColumnDefinitions = (actionsCellRenderer: (params: CellParams) => React.ReactElement): Array<ColDef> => {
  return [
    {
      field: 'actions',
      disableColumnMenu: true,
      headerName: ' ',
      cellClassName: buildCellClassParams,
      sortable: false,
      width: 52,
      renderCell: actionsCellRenderer,
    },
    {
      field: 'app',
      headerName: 'Asset',
      cellClassName: buildCellClassParams,
      flex: 1,
    },
    {
      field: 'version',
      disableColumnMenu: true,
      headerName: 'Latest Version',
      cellClassName: buildCellClassParams,
      sortable: false,
      flex: 1,
    },
  ];
};

export default getColumnDefinitions;
