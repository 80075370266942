import * as React from 'react';
import { Button, withStyles, ButtonProps } from '@material-ui/core';

const StyledButton = withStyles({
  root: {
    fontWeight: 'bold',
  },
})(Button);

export default function GridToolbarButton(props: ButtonProps) {
  return <StyledButton {...props} color="primary" />;
}
