export const FETCH_MACHINES_LIST = 'machines/list/fetch';
export const SET_MACHINES_LIST = 'machines/list/set';
export const SET_MACHINES_LIST_ERROR = 'machines/list/setError';
export const ADD_MACHINES_LIST_MACHINE = 'machines/list/addMachine';
export const SET_MACHINES_LIST_MACHINE = 'machines/list/setMachine';
export const REMOVE_MACHINES_LIST_MACHINE = 'machines/list/removeMachine';
export const CREATE_MACHINE = 'machines/create';
export const UPDATE_MACHINE = 'machines/update';
export const DELETE_MACHINE = 'machines/delete';
export const COMPLETE_MACHINE_OPERATION = 'machines/completeOperation';

export enum MachineProfile {
  unknown = 'GS_UNKNOWN',
  kiosk = 'GS_KIOSK',
  client = 'GS_CLIENT',
  spectator = 'GS_SPECTATOR',
}

export interface EntityConfig {
  id: string;
  version: string;
}

export interface MachineConfig {
  alias: string;
  group: string;
  entities: Array<EntityConfig>;
  profile: MachineProfile;
}

export interface Machine {
  id: string;
  config: MachineConfig;
}

export interface IFetchMachinesListAction {
  type: typeof FETCH_MACHINES_LIST;
}

export type ISetMachinesListPayload = Array<Machine>;

export interface ISetMachinesListAction {
  type: typeof SET_MACHINES_LIST;
  payload: ISetMachinesListPayload;
}

export interface ISetMachinesListErrorAction {
  type: typeof SET_MACHINES_LIST_ERROR;
}

export interface IAddMachineListMachinePayload {
  machine: Machine;
}

export interface IAddMachineListMachineAction {
  type: typeof ADD_MACHINES_LIST_MACHINE;
  payload: IAddMachineListMachinePayload;
}

export interface ISetMachineListMachinePayload {
  machine: Machine;
}

export interface ISetMachineListMachineAction {
  type: typeof SET_MACHINES_LIST_MACHINE;
  payload: ISetMachineListMachinePayload;
}

export interface IRemoveMachineListMachinePayload {
  machine: Machine;
}

export interface IRemoveMachineListMachineAction {
  type: typeof REMOVE_MACHINES_LIST_MACHINE;
  payload: IRemoveMachineListMachinePayload;
}

export interface ICreateMachinePayload {
  machine: Machine;
}

export interface ICreateMachineAction {
  type: typeof CREATE_MACHINE;
  payload: ICreateMachinePayload;
}

export interface IUpdateMachinePayload {
  machine: Machine;
}

export interface IUpdateMachineAction {
  type: typeof UPDATE_MACHINE;
  payload: IUpdateMachinePayload;
}

export interface IDeleteMachinePayload {
  machine: Machine;
}

export interface IDeleteMachineAction {
  type: typeof DELETE_MACHINE;
  payload: IDeleteMachinePayload;
}

export interface ICompleteMachineOperationPayload {
  machine: Machine;
}

export interface ICompleteMachineOperationAction {
  type: typeof COMPLETE_MACHINE_OPERATION;
  payload: ICompleteMachineOperationPayload;
}

export type IMachinesActionTypes =
  | IFetchMachinesListAction
  | ISetMachinesListAction
  | ISetMachinesListErrorAction
  | IAddMachineListMachineAction
  | ISetMachineListMachineAction
  | IRemoveMachineListMachineAction
  | ICreateMachineAction
  | IUpdateMachineAction
  | IDeleteMachineAction
  | ICompleteMachineOperationAction;
