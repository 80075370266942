import * as AssetsTypes from 'types/assets';

const initialState = {
  isLoading: false,
  error: false,
  data: [],
};

export default function AssetsReducer(state = initialState, action: AssetsTypes.IAssetsActionTypes) {
  switch (action.type) {
    case AssetsTypes.FETCH_ASSETS_LIST: {
      return {
        isLoading: true,
        error: false,
        data: [],
      };
    }

    case AssetsTypes.SET_ASSETS_LIST: {
      return {
        isLoading: false,
        error: false,
        data: action.payload,
      };
    }

    case AssetsTypes.SET_ASSETS_LIST_ERROR: {
      return {
        isLoading: false,
        error: true,
        data: [],
      };
    }

    default:
      return state;
  }
}
