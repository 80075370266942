import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as GroupsTypes from 'types/groups';
import api from 'api';
import {
  addGroup,
  setGroup,
  clearGroup,
  setGroupsList,
  setGroupsListError,
  completeGroupOperation,
} from 'actions/groups';
import { addNotification } from 'actions/notification';
import { getAuthToken, getPayload } from 'api/utils';
import { CANCEL } from 'redux-saga';

export function* getGroups() {
  try {
    const token: String = yield getAuthToken();

    // @ts-ignore
    const request = yield call(api.groups.getGroups, token);
    request[CANCEL] = () => request.abort();

    yield put(setGroupsList(getPayload(yield request)));
  } catch (error) {
    yield put(addNotification('Failed to fetch groups configuration.', 'error'));
    yield put(setGroupsListError());
  }
}

export function* createGroup({ payload, meta }: GroupsTypes.ICreateGroupAction) {
  const { config } = payload;
  const { alias } = config;

  try {
    const token: String = yield getAuthToken();
    // @ts-ignore
    const result = yield yield call(api.groups.createGroup, config, token);
    const createdGroup = getPayload(result);

    yield put(addNotification(`${alias} successfully created.`, 'success'));
    yield put(addGroup(createdGroup));
  } catch (error) {
    yield put(addNotification(`Failed to create ${alias}!`, 'error'));
  }

  yield put(completeGroupOperation(meta.id));
}

export function* updateGroup({ payload, meta }: GroupsTypes.IUpdateGroupAction) {
  const { group } = payload;
  const { id } = group;

  try {
    const token: String = yield getAuthToken();
    // @ts-ignore
    const result = yield yield call(api.groups.updateGroup, group, token);
    const updatedGroup = getPayload(result);

    yield put(addNotification(`${id} successfully updated.`, 'success'));
    yield put(setGroup(updatedGroup));
  } catch (error) {
    yield put(addNotification(`Failed to update ${id}!`, 'error'));
  }

  yield put(completeGroupOperation(meta.id));
}

export function* deleteGroup({ payload, meta }: GroupsTypes.IDeleteGroupAction) {
  const { group } = payload;
  const { id } = group;

  try {
    const token: String = yield getAuthToken();
    // @ts-ignore
    yield yield call(api.groups.deleteGroup, group, token);

    yield put(addNotification(`${id} successfully deleted.`, 'success'));
    yield put(clearGroup(group));
  } catch (error) {
    yield put(addNotification(`Failed to delete ${id}!`, 'error'));
  }

  yield put(completeGroupOperation(meta.id));
}

export default function* getAssetsWatcherSaga() {
  return all([
    // @ts-ignore
    yield takeLatest(GroupsTypes.FETCH_GROUPS_LIST, getGroups),
    // @ts-ignore
    yield takeEvery(GroupsTypes.CREATE_GROUP, createGroup),
    // @ts-ignore
    yield takeEvery(GroupsTypes.UPDATE_GROUP, updateGroup),
    // @ts-ignore
    yield takeEvery(GroupsTypes.DELETE_GROUP, deleteGroup),
  ]);
}
