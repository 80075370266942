import * as React from 'react';
import { Machine } from 'types/machines';
import useMachinesActions from 'hooks/useMachinesActions';

import MachinesGridView from './MachinesGridView';

interface Props {
  machines: Array<Machine>;
  isLoading: boolean;
  groupId?: string;
}

export default function MachinesView(props: Props) {
  const machinesActions = useMachinesActions();

  return <MachinesGridView {...props} actions={machinesActions} />;
}
