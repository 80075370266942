import * as React from 'react';
import { RowId, SelectionChangeParams } from '@material-ui/data-grid';

import StyledGrid from 'components/grid/StyledGrid';
import DeleteDialog from 'components/DeleteDialog';
import CreateGroupDialog from 'components/CreateGroupDialog';
import LayoutContainer from 'components/LayoutContainer';
import GridToolbar from 'components/grid/GridToolbar';
import GridToolbarButton from 'components/grid/GridToolbarButton';
import { Group, GroupConfig } from 'types/groups';

import getColumnDefinitions from './columnDefinitions';

interface Props {
  isLoading: boolean;
  groups: Array<Group>;
  actions: any;
}

const getDeleteTitle = (groupsList: Array<RowId>, groups: Array<Group>) => {
  return groupsList
    .map((item) => groups.find((group) => group.id === item))
    .filter(Boolean)
    .map((group) => `'${group!.config.alias}'`)
    .join(', ');
};

const getDeleteConfirmationText = (groupsList: Array<RowId>) => {
  if (groupsList.length === 1) return groupsList[0].toString();
  return 'DELETE GROUPS';
};

export default function GroupsView({ isLoading, groups, actions }: Props) {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<Array<RowId>>([]);

  const openCreateDialog = () => setCreateOpen(true);
  const closeCreateDialog = () => setCreateOpen(false);
  const openDeleteDialog = () => setDeleteOpen(true);
  const closeDeleteDialog = () => setDeleteOpen(false);

  return (
    <LayoutContainer>
      <GridToolbar>
        <GridToolbarButton onClick={openCreateDialog}>Create</GridToolbarButton>
        <GridToolbarButton color="primary" disabled={selectedRows.length === 0} onClick={openDeleteDialog}>
          Delete
        </GridToolbarButton>
      </GridToolbar>

      <StyledGrid
        loading={isLoading}
        rows={groups}
        columns={getColumnDefinitions()}
        onSelectionChange={({ rowIds }: SelectionChangeParams) => setSelectedRows(rowIds)}
      />

      <CreateGroupDialog
        open={createOpen}
        onClose={closeCreateDialog}
        onConfirm={(config: GroupConfig) => {
          closeCreateDialog();
          actions.create(config);
        }}
      />

      <DeleteDialog
        open={deleteOpen}
        titleText={getDeleteTitle(selectedRows, groups)}
        confirmationText={getDeleteConfirmationText(selectedRows)}
        typeText={`group${selectedRows.length > 1 ? 's' : ''}`}
        onClose={closeDeleteDialog}
        onConfirm={() => {
          setSelectedRows([]);
          closeDeleteDialog();

          selectedRows.forEach((groupId) => {
            const groupToDelete = groups.find(({ id }) => groupId === id);
            if (groupToDelete) {
              actions.delete(groupToDelete!!);
            }
          });
        }}
      />
    </LayoutContainer>
  );
}
