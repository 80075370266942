import * as React from 'react';
import { useDispatch } from 'react-redux';

import { fetchAssetsList, uploadAsset } from 'actions/assets';

export default function useAssetsActions() {
  const dispatch = useDispatch();

  const [actions] = React.useState({
    fetch: () => dispatch(fetchAssetsList()),
    uploadAsset: (file: File, name: string, version: string) => dispatch(uploadAsset(file, name, version)),
  });

  return actions;
}
