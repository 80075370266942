import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { Pages } from 'routes';
import AuthUserContext from './context';

const withAuthorization = (Component: any) =>
  function (props: any | undefined) {
    const auth = React.useContext(AuthUserContext);
    if (!auth) {
      return <Redirect to={Pages.LOG_IN} />;
    }

    return <Component {...props} />;
  };

export default withAuthorization;
