import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Box, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';

import { Machine } from 'types/machines';
import { GroupConfig } from 'types/groups';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '42vw',
    minWidth: '480px',
  },
  dialogContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    padding: theme.spacing(3),
  },
  autocomplete: {
    width: '100%',
  },
}));

export interface Props {
  open: boolean;
  machines: Array<Machine>;
  onClose: () => void;
  onConfirm: (config: GroupConfig) => void;
}

export default function CreateDialogView({ open, machines, onClose, onConfirm }: Props) {
  const classes = useStyles();
  const [alias, setAlias] = React.useState('');
  const [selectedMachines, setSelectedMachines] = React.useState<Array<Machine>>([]);

  React.useEffect(() => setSelectedMachines([]), [machines]);
  React.useEffect(() => {
    setAlias('');
    setSelectedMachines([]);
  }, [open]);

  const confirmConfiguration = () =>
    onConfirm({
      alias,
      machines: selectedMachines.map(({ id }) => id),
    });

  const handleAliasChange = (event: React.ChangeEvent<HTMLInputElement>) => setAlias(event.target.value);

  return (
    <>
      <Dialog
        classes={{
          paper: classes.dialog,
        }}
        open={open}
        onClose={onClose}
        aria-labelledby="create-group-dialog-title"
      >
        <DialogTitle id="create-group-dialog-title">Create group</DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <Box pb={2}>
            <TextField
              autoFocus
              autoComplete="off"
              variant="outlined"
              fullWidth
              margin="dense"
              id="alias"
              label="Group Name"
              type="text"
              value={alias}
              onChange={handleAliasChange}
            />
          </Box>

          <Autocomplete
            className={classes.autocomplete}
            multiple
            id="machines-tags"
            fullWidth
            filterSelectedOptions
            value={selectedMachines}
            onChange={(event, newValue) => setSelectedMachines(newValue)}
            options={machines}
            getOptionLabel={(option) => option.config.alias}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => <Chip label={option.config.alias} {...getTagProps({ index })} />)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                margin="dense"
                label="Machines"
                variant="outlined"
                placeholder="Machines"
              />
            )}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button disabled={alias.length === 0} onClick={confirmConfiguration} variant="contained" color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
