import * as React from 'react';
import { Edit } from '@material-ui/icons';

import StyledIconButton from 'components/StyledIconButton';

interface Props {
  disabled?: boolean;
  onClick: () => void;
}

export default function StyledEditButton({ disabled = false, onClick }: Props) {
  return (
    <StyledIconButton size="small" disabled={disabled} aria-label="edit" onClick={onClick}>
      <Edit fontSize="small" />
    </StyledIconButton>
  );
}
