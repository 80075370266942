import * as React from 'react';
import { useSelector } from 'react-redux';

import useDataRequest from 'hooks/useDataRequest';
import assetsSelector from 'selectors/assetsSelector';
import useTopBarTitleActions from 'hooks/useTopBarTitleActions';

import AssetsView from './AssetsView';

export default function Assets() {
  useDataRequest();
  const { isLoading, assets } = useSelector(assetsSelector);
  const topBarActions = useTopBarTitleActions();

  React.useEffect(() => {
    topBarActions.setTitle('Assets');
  }, [topBarActions]);

  return <AssetsView isLoading={isLoading} assets={assets} />;
}
