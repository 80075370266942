import * as uuid from 'uuid';

import * as Notification from 'types/notification';

export function addNotification(message: string, variant: string): Notification.IAddNotificationAction {
  return {
    type: Notification.ADD_NOTIFICATION,
    payload: {
      id: uuid.v4(),
      message,
      variant,
    },
  };
}

export function removeNotification(id: string): Notification.IRemoveNotificationAction {
  return {
    type: Notification.REMOVE_NOTIFICATION,
    payload: {
      id,
    },
  };
}
