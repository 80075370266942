import * as React from 'react';
import { IconButton } from '@material-ui/core';
import { Delete, LibraryAdd } from '@material-ui/icons';
import { ColDef, DataGrid, ValueFormatterParams } from '@material-ui/data-grid';

import { EntityConfig } from 'types/machines';
import AssetVersionCell from './AssetVersionCell';

export interface Props {
  assets: Array<EntityConfig>;
  onAdd: (target: HTMLElement) => void;
  onChange: (entity: EntityConfig) => void;
  onRemove: (assetName: string) => void;
}

export default function AssetsGrid({ assets, onAdd, onChange, onRemove }: Props) {
  const onAddClick = (event: React.MouseEvent<HTMLButtonElement>) => onAdd(event.currentTarget);

  const colDefs: Array<ColDef> = [
    {
      field: 'id',
      headerName: 'Asset',
      flex: 3,
    },
    {
      field: 'version',
      sortable: false,
      headerName: 'Version',
      flex: 2,
      renderCell: (params: ValueFormatterParams) => {
        const { row } = params;
        const { id, version } = row;

        return <AssetVersionCell id={id.toString()} version={version} onChange={onChange} />;
      },
    },
    {
      field: 'actions',
      sortable: false,
      renderHeader: () => (
        <IconButton aria-label="delete" onClick={onAddClick}>
          <LibraryAdd fontSize="small" />
        </IconButton>
      ),
      flex: 1,
      renderCell: (params: ValueFormatterParams) => {
        const { row } = params;
        const { id } = row;

        return (
          <IconButton aria-label="delete" onClick={() => onRemove(id.toString())}>
            <Delete fontSize="small" />
          </IconButton>
        );
      },
    },
  ];

  return <DataGrid disableColumnMenu density="compact" rows={assets} columns={colDefs} />;
}
