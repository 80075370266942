import * as AssetsTypes from 'types/assets';

export function fetchAssetsList(): AssetsTypes.IFetchAssetsListAction {
  return {
    type: AssetsTypes.FETCH_ASSETS_LIST,
  };
}

export function setAssetsList(assets: Array<AssetsTypes.Asset>): AssetsTypes.ISetAssetsListAction {
  return {
    type: AssetsTypes.SET_ASSETS_LIST,
    payload: assets,
  };
}

export function setAssetsListError(): AssetsTypes.ISetAssetsListErrorAction {
  return {
    type: AssetsTypes.SET_ASSETS_LIST_ERROR,
  };
}

export function uploadAsset(file: File, name: string, version: string): AssetsTypes.IUploadAssetAction {
  return {
    type: AssetsTypes.UPLOAD_ASSET,
    payload: {
      file,
      name,
      version,
    },
  };
}

export function setUploadProgress(name: string, progress: number): AssetsTypes.ISetAssetUploadProgressAction {
  return {
    type: AssetsTypes.SET_ASSET_UPLOAD_PROGRESS,
    payload: {
      name,
      progress,
    },
  };
}

export function finishAssetUpload(name: string): AssetsTypes.IFinishAssetUploadAction {
  return {
    type: AssetsTypes.FINISH_ASSET_UPLOAD,
    payload: {
      name,
    },
  };
}
