import * as React from 'react';

import useAssetsActions from 'hooks/useAssetsActions';
import useGroupsActions from 'hooks/useGroupsActions';
import useMachinesActions from 'hooks/useMachinesActions';

export default function useDataRequest() {
  const assetsActions = useAssetsActions();
  const groupsActions = useGroupsActions();
  const machinesActions = useMachinesActions();

  React.useEffect(() => {
    assetsActions.fetch();
  }, [assetsActions]);

  React.useEffect(() => {
    groupsActions.fetch();
  }, [groupsActions]);

  React.useEffect(() => {
    machinesActions.fetch();
  }, [machinesActions]);

  return null;
}
