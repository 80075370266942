export const FETCH_GROUPS_LIST = 'groups/fetchList';
export const SET_GROUPS_LIST = 'groups/setList';
export const SET_GROUPS_LIST_ERROR = 'groups/setListError';
export const ADD_GROUP = 'groups/add';
export const SET_GROUP = 'groups/set';
export const CLEAR_GROUP = 'groups/clear';
export const CREATE_GROUP = 'groups/create';
export const UPDATE_GROUP = 'groups/update';
export const DELETE_GROUP = 'groups/delete';
export const COMPLETE_GROUP_OPERATION = 'groups/completeOperation';

export interface GroupConfig {
  alias: string;
  machines: Array<string>;
}

export interface Group {
  id: string;
  config: GroupConfig;
}

export interface IGroupsActionMeta {
  id: string;
}

export interface IFetchGroupsListAction {
  type: typeof FETCH_GROUPS_LIST;
}

export type ISetGroupsListPayload = Array<Group>;

export interface ISetGroupsListAction {
  type: typeof SET_GROUPS_LIST;
  payload: ISetGroupsListPayload;
}

export interface IAddGroupPayload {
  group: Group;
}

export interface IAddGroupAction {
  type: typeof ADD_GROUP;
  payload: IAddGroupPayload;
}

export interface ISetGroupPayload {
  group: Group;
}

export interface ISetGroupAction {
  type: typeof SET_GROUP;
  payload: ISetGroupPayload;
}

export interface IClearGroupPayload {
  group: Group;
}

export interface IClearGroupAction {
  type: typeof CLEAR_GROUP;
  payload: IClearGroupPayload;
}

export interface ISetGroupsListErrorAction {
  type: typeof SET_GROUPS_LIST_ERROR;
}

export interface ICreateGroupPayload {
  config: GroupConfig;
}

export interface ICreateGroupAction {
  type: typeof CREATE_GROUP;
  payload: ICreateGroupPayload;
  meta: IGroupsActionMeta;
}

export interface IUpdateGroupPayload {
  group: Group;
}

export interface IUpdateGroupAction {
  type: typeof UPDATE_GROUP;
  payload: IUpdateGroupPayload;
  meta: IGroupsActionMeta;
}

export interface IDeleteGroupPayload {
  group: Group;
}

export interface IDeleteGroupAction {
  type: typeof DELETE_GROUP;
  payload: IDeleteGroupPayload;
  meta: IGroupsActionMeta;
}

export interface ICompleteGroupOperationPayload {
  operationId: string;
}

export interface ICompleteGroupOperationAction {
  type: typeof COMPLETE_GROUP_OPERATION;
  meta: IGroupsActionMeta;
}

export type IGroupsActionTypes =
  | IFetchGroupsListAction
  | ISetGroupsListAction
  | ISetGroupsListErrorAction
  | IAddGroupAction
  | ISetGroupAction
  | IClearGroupAction
  | ICreateGroupAction
  | IUpdateGroupAction
  | IDeleteGroupAction
  | ICompleteGroupOperationAction;
