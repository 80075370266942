import * as React from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  makeStyles,
} from '@material-ui/core';

export interface Props {
  open: boolean;
  titleText?: string;
  typeText?: string;
  confirmationText?: string;
  onClose: () => void;
  onConfirm: () => void;
}

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: theme.spacing(3),
  },
}));

export default function DeleteDialogView({
  open,
  titleText = '',
  typeText = '',
  confirmationText = '',
  onClose,
  onConfirm,
}: Props) {
  const classes = useStyles();
  const [confirmationId, setConfirmationId] = React.useState<string>('');
  React.useEffect(() => setConfirmationId(''), [open]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setConfirmationId(event.target.value);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <span>
          Delete <b>{titleText}</b>
        </span>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          You are about to delete {typeText}, type this <b>{confirmationText}</b> in form dialog to confirm deletion.
        </DialogContentText>
        <TextField
          autoFocus
          autoComplete="off"
          margin="dense"
          id="name"
          label="Confirmation"
          type="text"
          variant="outlined"
          value={confirmationId}
          onChange={handleChange}
          fullWidth
        />
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button disabled={confirmationText !== confirmationId} onClick={onConfirm} variant="contained" color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
