import { Response } from 'superagent';
import * as firebase from 'firebase/app';

export function getPayload(response: Response) {
  return response.body ?? response.text;
}

export async function getAuthToken(): Promise<string> {
  return firebase.auth().currentUser?.getIdToken() ?? Promise.reject();
}
