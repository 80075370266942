import * as React from 'react';
import { useSelector } from 'react-redux';

import { Machine } from 'types/machines';
import assetsSelector from 'selectors/assetsSelector';
import groupsSelector from 'selectors/groupsSelector';

import ConfigureMachineDialogView from './ConfigureMachineDialogView';

export interface Props {
  machine?: Machine;
  onConfirm: (machine: Machine, exists: boolean) => void;
  onClose: () => void;
}

export default function ConfigureMachineDialog(props: Props) {
  const { assets } = useSelector(assetsSelector);
  const { groups } = useSelector(groupsSelector);

  return <ConfigureMachineDialogView {...props} assets={assets} groups={groups} />;
}
