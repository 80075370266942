import * as React from 'react';

import useAssetsActions from 'hooks/useAssetsActions';

import ConfigureAssetDialogView from './ConfigureAssetDialogView';

export interface Props {
  assetId: string | null;
  onClose: () => void;
}

export default function ConfigureAssetDialog(props: Props) {
  const assetsActions = useAssetsActions();

  return <ConfigureAssetDialogView {...props} actions={assetsActions} />;
}
