import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import useAssetVersions from 'hooks/useAssetVersions';

import AssetSelect, { AssetFile } from './AssetSelect';
import VersionSelect from './VersionSelect';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
  },
  content: {
    paddingBottom: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(3),
  },
}));

export interface Props {
  assetId: string | null;
  onClose: () => void;
  actions: any;
}

export default function AssetUploadDialogView({ assetId, onClose, actions }: Props) {
  const classes = useStyles();
  const [name, setName] = React.useState<string>('');
  const [version, setVersion] = React.useState<string>('');
  const [file, setFile] = React.useState<AssetFile>(null);
  const versionOptions = useAssetVersions(assetId);

  React.useEffect(() => {
    if (assetId) setName(assetId);
  }, [assetId]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);

  const exists = Boolean(assetId);

  return (
    <Dialog open onClose={onClose} aria-labelledby="asset-dialog-title">
      <DialogTitle id="asset-dialog-title">
        {exists ? (
          <span>
            Upload new <b>{name}</b> version
          </span>
        ) : (
          <span>Create asset</span>
        )}
      </DialogTitle>

      <DialogContent className={classes.content}>
        {!exists && (
          <TextField
            autoFocus
            autoComplete="off"
            margin="dense"
            variant="outlined"
            id="name"
            label="Name"
            type="text"
            value={name}
            onChange={handleNameChange}
            fullWidth
          />
        )}

        <VersionSelect
          isNew={!exists}
          version={version}
          versionOptions={versionOptions || []}
          onVersionChange={setVersion}
        />

        <AssetSelect asset={file} onAssetChange={setFile} />
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={name.length === 0 || file === null}
          onClick={() => {
            onClose();
            actions.uploadAsset(file!!, name, version);
          }}
          variant="contained"
          color="primary"
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
