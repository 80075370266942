import * as React from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { Button, Box, CssBaseline, Container, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router';

import Copyright from 'components/Copyright';
import { AuthUserContext } from 'components/Session';
import { Pages } from 'routes';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const auth = React.useContext(AuthUserContext);
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [remember, setRemember] = React.useState<boolean>(false);

  const handleSignIn = React.useCallback(() => {
    firebase
      .auth()
      .setPersistence(remember ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION)
      .then(() => firebase.auth().signInWithEmailAndPassword(email, password))
      .catch(function (error) {
        console.error(`errorCode: ${error.errorCode}, errorMessage: ${error.errorMessage}`);
      });
  }, [email, password, remember]);

  function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  function handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
  }

  function handleRememberChange(event: React.ChangeEvent<HTMLInputElement>) {
    setRemember((checked) => !checked);
  }

  if (auth) {
    return <Redirect to={Pages.DASHBOARD} />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} noValidate onSubmit={(e) => e.preventDefault()}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleEmailChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handlePasswordChange}
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={remember} onChange={handleRememberChange} />}
            label="Remember me"
          />
          <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={handleSignIn}>
            Sign In
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
