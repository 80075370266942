import * as React from 'react';
import { useSelector } from 'react-redux';

import selectUnassignedMachines from 'selectors/selectUnassignedMachines';
import useDataRequest from 'hooks/useDataRequest';
import useTopBarTitleActions from 'hooks/useTopBarTitleActions';
import MachinesGrid from 'components/MachinesGrid';

export default function Machines() {
  useDataRequest();
  const topBarActions = useTopBarTitleActions();
  const { isLoading, machines } = useSelector(selectUnassignedMachines);

  React.useEffect(() => {
    topBarActions.setTitle('Unassigned machines');
  }, [topBarActions]);

  return <MachinesGrid isLoading={isLoading} machines={machines} />;
}
