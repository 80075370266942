import * as React from 'react';
import { useSelector } from 'react-redux';

import selectTopBarTitle from 'selectors/topBarTitleSelector';

import TopBarView, { ToggleTopBarExpansion } from './TopBarView';

export interface Props {
  expanded: boolean;
  toggleExpansion: ToggleTopBarExpansion;
}

export default function TopBar(props: Props) {
  const topBarTitle = useSelector(selectTopBarTitle);

  return <TopBarView {...props} title={topBarTitle} />;
}
