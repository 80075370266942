import * as MachinesTypes from 'types/machines';
import { Operation, OperationType, OperationVariant } from 'types/operation';

export type MachinesStateReducerState = Array<Operation>;

const createMachineOperation = (machine: MachinesTypes.Machine, operation: OperationVariant) => ({
  type: OperationType.Machine,
  operation,
  id: machine.id,
  progress: null,
});

export default function MachinesStateReducer(
  state: MachinesStateReducerState = [],
  action: MachinesTypes.IMachinesActionTypes
) {
  switch (action.type) {
    case MachinesTypes.CREATE_MACHINE: {
      const { machine } = action.payload;
      return [...state, createMachineOperation(machine, OperationVariant.Create)];
    }

    case MachinesTypes.UPDATE_MACHINE: {
      const { machine } = action.payload;
      return [...state, createMachineOperation(machine, OperationVariant.Update)];
    }

    case MachinesTypes.DELETE_MACHINE: {
      const { machine } = action.payload;
      return [...state, createMachineOperation(machine, OperationVariant.Delete)];
    }

    case MachinesTypes.COMPLETE_MACHINE_OPERATION: {
      const { machine } = action.payload;
      return state.filter(({ id }) => id !== machine.id);
    }

    default:
      return state;
  }
}
