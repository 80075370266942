import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { withAuthentication } from 'components/Session';
import SignIn from 'components/SignIn';
import Dashboard from 'components/Dashboard';
import { Pages } from 'routes';

import './App.css';

function App() {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={Pages.LOG_IN} />
      </Route>
      <Route path={Pages.LOG_IN} default>
        <SignIn />
      </Route>
      <Route path={Pages.DASHBOARD}>
        <Dashboard />
      </Route>
    </Switch>
  );
}

export default withAuthentication(App);
