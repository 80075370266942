import * as React from 'react';
import * as firebase from 'firebase/app';
import clsx from 'clsx';
import { deepOrange } from '@material-ui/core/colors';
import { AppBar, Toolbar, Typography, Avatar, IconButton, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';

import { Dimensions } from 'utils/Constants';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: Dimensions.DRAWER_WIDTH,
    width: `calc(100% - ${Dimensions.DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));

export interface ToggleTopBarExpansion {
  (): void;
}

export interface Props {
  expanded: boolean;
  toggleExpansion: ToggleTopBarExpansion;
  title: string;
}

export default function TopBarView({ expanded, toggleExpansion, title }: Props) {
  const classes = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleMenuClick(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setMenuAnchorEl(null);
  }

  function onLogout() {
    handleMenuClose();

    firebase.auth().signOut();
  }

  return (
    <AppBar position="absolute" className={clsx(classes.appBar, expanded && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleExpansion}
          className={clsx(classes.menuButton, expanded && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
          {title ?? 'Dashboard'}
        </Typography>
        <IconButton edge="start" color="inherit" aria-label="open menu" onClick={handleMenuClick}>
          <Avatar alt="Remy Sharp" src="" className={classes.orange}>
            B
          </Avatar>
        </IconButton>
        <Menu
          id="simple-menu"
          getContentAnchorEl={null}
          anchorEl={menuAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={onLogout}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
