import * as React from 'react';
import clsx from 'clsx';
import { ValueFormatterParams, ValueGetterParams, CellClassParams, ColDef, CellParams } from '@material-ui/data-grid';

function getAssetsCount(params: ValueGetterParams) {
  const { config } = params.row;

  return `${config?.entities?.length} asset(s)`;
}

function getProfile(params: ValueGetterParams) {
  const { config } = params.row;

  return config?.profile ?? '';
}

function buildCellClassParams(params: CellClassParams) {
  return clsx('styled-grid', {
    inProgress: params.row.actionInProgress,
  });
}

const getColumnDefinitions = (actionsCellRenderer: (params: CellParams) => React.ReactElement): Array<ColDef> => {
  return [
    {
      field: 'actions',
      disableColumnMenu: true,
      headerName: ' ',
      cellClassName: buildCellClassParams,
      renderCell: actionsCellRenderer,
      width: 52,
    },
    {
      field: 'config.alias',
      headerName: 'Machine',
      cellClassName: buildCellClassParams,
      renderCell: (params: ValueFormatterParams) => {
        const { config } = params.row;
        return config?.alias ?? '';
      },
      flex: 3,
    },
    {
      field: 'entities',
      headerName: 'Assets',
      cellClassName: buildCellClassParams,
      valueGetter: getAssetsCount,
      flex: 2,
    },
    {
      field: 'config.profile',
      headerName: 'Profile',
      cellClassName: buildCellClassParams,
      valueGetter: getProfile,
      flex: 2,
    },
  ];
};

export default getColumnDefinitions;
