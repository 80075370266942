export const SET_TOB_BAR_TITLE = 'topBarTitle/set';

export interface ISetTopBarTitlePayload {
  title: string;
}

export interface ISetTopBarTitleAction {
  type: typeof SET_TOB_BAR_TITLE;
  payload: ISetTopBarTitlePayload;
}

export type ITopBarActionTypes = ISetTopBarTitleAction;
