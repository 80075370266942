import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase/app';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import AuthUserContext from './context';

const withAuthentication = (Component: any) =>
  function (props: any) {
    const [authUser, setAuthUser] = useState<firebase.User | null>(firebase.auth().currentUser);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
      const listener = firebase.auth().onAuthStateChanged((user) => {
        setAuthUser(user);
        setLoading(false);
      });

      return () => {
        listener();
      };
    }, []);

    if (loading) {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          <CircularProgress />
        </Grid>
      );
    }

    return (
      <AuthUserContext.Provider value={authUser}>
        <Component {...props} />
      </AuthUserContext.Provider>
    );
  };

export default withAuthentication;
