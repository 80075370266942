import * as React from 'react';
import _ from 'lodash';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { EntityConfig, Machine, MachineProfile } from 'types/machines';
import { Asset } from 'types/assets';
import { Group } from 'types/groups';

import AssetsGrid from './AssetsGrid';
import AssetsListMenu from './AssetsListMenu';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '62vw',
    minWidth: '480px',
    height: '80vh',
    minHeight: '400px',
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  gridTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  gridContainer: {
    flex: 1,
  },
  actions: {
    padding: theme.spacing(3),
  },
}));

export interface Props {
  machine?: Machine;
  assets: Array<Asset>;
  groups: Array<Group>;
  onConfirm: (machine: Machine, exists: boolean) => void;
  onClose: () => void;
}

export default function ConfigureMachineDialogView(props: Props) {
  const classes = useStyles();

  const [machine, setMachine] = React.useState<Machine | undefined>(undefined);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const exists = Boolean(props.machine?.id);

  React.useEffect(() => {
    if (props.machine) {
      setMachine(_.cloneDeep(props.machine));
    } else {
      setMachine(undefined);
    }
  }, [props.machine]);

  const { assets, onClose, onConfirm } = props;

  const confirmConfiguration = () => onConfirm(machine!!, exists);
  const handleAliasChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMachine = _.cloneDeep(machine!!);
    newMachine.config.alias = event.target.value;

    setMachine(newMachine);
  };

  const handleIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMachine = _.cloneDeep(machine!!);
    newMachine.id = event.target.value;

    setMachine(newMachine);
  };

  const handleProfileChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newMachine = _.cloneDeep(machine!!);
    newMachine.config.profile = event.target.value as MachineProfile;

    setMachine(newMachine);
  };

  const handleAssetChange = (entity: EntityConfig) => {
    const newMachine = _.cloneDeep(machine!!);
    newMachine.config.entities = newMachine.config.entities.map((item) => (entity.id === item.id ? entity : item));

    setMachine(newMachine);
  };

  const handleAssetRemove = (item: string) => {
    const newMachine = _.cloneDeep(machine!!);
    newMachine.config.entities = newMachine.config.entities.filter((entity) => entity.id !== item);

    setMachine(newMachine);
  };

  const handleAssetAdd = (item: string) => {
    const newMachine: Machine = _.cloneDeep(machine!!);
    newMachine.config.entities = [
      ...newMachine!!.config.entities,
      {
        id: item,
        version: 'latest',
      },
    ];

    setMachine(newMachine);
  };

  const onAddClick = (target: HTMLElement) => setAnchorEl(target);
  const handleMenuClose = () => setAnchorEl(null);

  if (!machine) {
    return null;
  }

  const { config, id } = machine;
  const { entities, alias, profile, group } = config;

  const actionText: string = exists ? 'Update' : 'Create';
  const availableAssets = assets.filter(({ app }) => !Boolean(entities.find((entity) => entity.id === app)));

  return (
    <>
      <Dialog
        classes={{
          paper: classes.dialog,
        }}
        open
        onClose={onClose}
        aria-labelledby="configure-dialog-title"
      >
        <DialogTitle className={classes.dialogTitle} id="configure-dialog-title">
          <Typography>
            {actionText} machine{exists && <b> {id}</b>}
          </Typography>
          {group && (
            <Typography>Assign to group {props.groups.find(({ id }) => id === group)?.config.alias}</Typography>
          )}
        </DialogTitle>

        <DialogContent>
          <div className={classes.dialogContent}>
            {!exists && (
              <Box pb={2}>
                <TextField
                  autoFocus
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  id="name"
                  label="Machine Id"
                  type="text"
                  value={id}
                  onChange={handleIdChange}
                />
              </Box>
            )}

            <Box pb={2}>
              <TextField
                autoFocus
                autoComplete="off"
                variant="outlined"
                fullWidth
                margin="dense"
                id="alias"
                label="Machine Name"
                type="text"
                value={alias}
                onChange={handleAliasChange}
              />
            </Box>

            <Box pb={4} pt={1}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="profile-label-id">Profile</InputLabel>
                <Select
                  labelId="profile-label-id"
                  id="profile-label"
                  value={profile || MachineProfile.unknown}
                  margin="dense"
                  onChange={handleProfileChange}
                  label="Profile"
                >
                  {Object.entries(MachineProfile).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {_.capitalize(key)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box className={classes.gridContainer}>
              <AssetsGrid
                assets={entities}
                onAdd={onAddClick}
                onChange={handleAssetChange}
                onRemove={handleAssetRemove}
              />
            </Box>
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmConfiguration} variant="contained" color="primary">
            {actionText}
          </Button>
        </DialogActions>
      </Dialog>

      <AssetsListMenu
        anchorEl={anchorEl}
        assets={availableAssets}
        onClose={handleMenuClose}
        onSelect={(asset) => {
          handleMenuClose();
          handleAssetAdd(asset);
        }}
      />
    </>
  );
}
