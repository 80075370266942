import { eventChannel, END, EventChannel } from 'redux-saga';
import superagent from 'superagent';
import _ from 'lodash';

import { headers, URL } from './constants';

async function getAssets(token: string): Promise<any> {
  const url = `${URL}/assets`;
  return superagent.get(url).set(headers.FirebaseAuth, token);
}

async function getVersions(id: string, token: string): Promise<any> {
  const url = `${URL}/assets/${id}/versions`;
  return superagent.get(url).set(headers.FirebaseAuth, token);
}

async function getUploadInfo(assetName: string, assetVersion: string, token: string): Promise<any> {
  const url = `${URL}/assets/${assetName}/uploadInfo?version=${assetVersion}`;
  return superagent.get(url).set(headers.FirebaseAuth, token);
}

function getUploadEventChannel(url: string, file: File): EventChannel<any> {
  return eventChannel((emitter) => {
    const onProgress = (event: superagent.ProgressEvent) => {
      const { percent } = event;
      emitter({ progress: percent ?? 0 });
    };

    const req = superagent
      .put(url)
      .on('progress', _.throttle(onProgress, 1000))
      .set('Content-Type', 'application/octet-stream')
      .send(file);

    req.then(
      () => {
        emitter({ result: true });
        emitter(END);
      },
      (error) => {
        emitter({ error });
        emitter(END);
      }
    );

    return () => {
      req.abort();
    };
  });
}

async function setLatestVersion(assetName: string, assetVersion: string, token: string): Promise<any> {
  const url = `${URL}/assets/${assetName}/setLatestVersion?version=${assetVersion}`;
  return superagent.put(url).set(headers.FirebaseAuth, token);
}

export default {
  getAssets,
  getVersions,
  getUploadInfo,
  getUploadEventChannel,
  setLatestVersion,
};
