import * as React from 'react';
import _ from 'lodash';
import { Menu, MenuItem, CircularProgress } from '@material-ui/core';

export interface Props {
  anchorEl: null | HTMLElement;
  versions: null | Array<string>;
  onClose: () => void;
  onClick: (option: string) => void;
}

export default function AssetVersionMenu({ anchorEl, versions, onClose, onClick }: Props) {
  return (
    <Menu id="lock-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onClose}>
      {_.isNil(versions) && (
        <MenuItem disabled>
          <CircularProgress />
        </MenuItem>
      )}
      {!_.isNil(versions) &&
        ['latest', ...versions].map((option) => (
          <MenuItem key={option} onClick={() => onClick(option)}>
            {option}
          </MenuItem>
        ))}
    </Menu>
  );
}
