import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { Group } from 'types/groups';
import { Machine } from 'types/machines';
import groupsSelector from 'selectors/groupsSelector';
import machinesSelector from 'selectors/machinesSelector';
import useDataRequest from 'hooks/useDataRequest';
import useTopBarTitleActions from 'hooks/useTopBarTitleActions';

import ConfigureGroupView from './ConfigureGroupView';

interface MatchParams {
  id: string;
}

export default function ConfigureView() {
  useDataRequest();
  const match = useRouteMatch<MatchParams>();
  const history = useHistory();
  const { groups, isLoading: groupsIsLoading } = useSelector(groupsSelector);
  const { machines, isLoading: machinesIsLoading } = useSelector(machinesSelector);
  const topBarActions = useTopBarTitleActions();

  const group = React.useMemo(() => {
    const { id } = match.params;

    return groups.find((item: Group) => item.id === id) as Group;
  }, [groups, match]);

  const groupMachines = React.useMemo(() => {
    return machines.filter((item: Machine) => item.config.group === group?.id);
  }, [group, machines]);

  React.useEffect(() => {
    topBarActions.setTitle('Group machines');
  }, [topBarActions]);

  const isLoading = groupsIsLoading || machinesIsLoading;

  return (
    <ConfigureGroupView
      isLoading={isLoading}
      group={group}
      machines={groupMachines}
      onBackClick={() => history.goBack()}
    />
  );
}
