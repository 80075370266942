import * as React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface Props {
  children: React.ReactNode;
}

export default function LayoutContainer({ children }: Props) {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
}
