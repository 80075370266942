import * as React from 'react';
import { useSelector } from 'react-redux';

import useGroupsActions from 'hooks/useGroupsActions';
import groupsSelector from 'selectors/groupsSelector';

import GroupsView from './GroupsView';
import useDataRequest from 'hooks/useDataRequest';
import useTopBarTitleActions from 'hooks/useTopBarTitleActions';

export default function Groups() {
  useDataRequest();
  const groupsActions = useGroupsActions();
  const topBarActions = useTopBarTitleActions();
  const { isLoading, groups } = useSelector(groupsSelector);

  React.useEffect(() => {
    topBarActions.setTitle('Groups');
  }, [topBarActions]);

  return <GroupsView isLoading={isLoading} groups={groups} actions={groupsActions} />;
}
