import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Machine } from 'types/machines';
import { createMachine, updateMachine, deleteMachine, fetchMachinesList } from 'actions/machines';

export default function useMachinesActions() {
  const dispatch = useDispatch();

  const [actions] = React.useState({
    fetch: () => dispatch(fetchMachinesList()),
    create: (machine: Machine) => dispatch(createMachine(machine)),
    update: (machine: Machine) => dispatch(updateMachine(machine)),
    delete: (machine: Machine) => dispatch(deleteMachine(machine)),
  });

  return actions;
}
