import { RootState } from 'reducers';

export default function unassignedMachinesSelector(state: RootState) {
  const { isLoading, error, data } = state.machines;

  return {
    isLoading,
    error,
    machines: data.filter(({ config }: any) => !Boolean(config.group)),
  };
}
