import { RootState } from 'reducers';
import { Operation } from 'types/operation';

export default function assetsSelector(state: RootState) {
  const { isLoading, error, data } = state.assets;
  const { assetsState } = state;

  return {
    isLoading,
    error,
    assets: data.map((item: any) => ({
      ...item,
      actionInProgress: Boolean(assetsState.find((operation: Operation) => operation.id === item.app)),
    })),
  };
}
