import * as React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

import { EntityConfig } from 'types/machines';
import useAssetVersions from 'hooks/useAssetVersions';
import StyledIconButton from 'components/StyledIconButton';

import AssetVersionMenu from './AssetVersionMenu';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  typography: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export interface Props {
  version: string;
  id: string;
  onChange: (entity: EntityConfig) => void;
}

export default function AssetVersionCell({ version, id, onChange }: Props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [requestedId, setRequestedId] = React.useState<string | null>(null);
  const assetVersions = useAssetVersions(requestedId);

  const handleOpenDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setRequestedId(id);
  };

  return (
    <>
      <div className={classes.container}>
        <Typography className={classes.typography} variant="body2" color="textPrimary" align="center">
          {version}
        </Typography>

        <StyledIconButton aria-label="open-versions-dropdown" onClick={handleOpenDropdown}>
          <ArrowDropDown fontSize="small" />
        </StyledIconButton>
      </div>

      <AssetVersionMenu
        anchorEl={anchorEl}
        versions={assetVersions}
        onClose={() => setAnchorEl(null)}
        onClick={(version) => {
          setAnchorEl(null);
          onChange({
            id,
            version,
          });
        }}
      />
    </>
  );
}
