import * as GroupsTypes from 'types/groups';
import * as uuid from 'uuid';

export function fetchGroupsList(): GroupsTypes.IFetchGroupsListAction {
  return {
    type: GroupsTypes.FETCH_GROUPS_LIST,
  };
}

export function setGroupsList(Groups: Array<GroupsTypes.Group>): GroupsTypes.ISetGroupsListAction {
  return {
    type: GroupsTypes.SET_GROUPS_LIST,
    payload: Groups,
  };
}

export function addGroup(group: GroupsTypes.Group): GroupsTypes.IAddGroupAction {
  return {
    type: GroupsTypes.ADD_GROUP,
    payload: {
      group,
    },
  };
}

export function setGroup(group: GroupsTypes.Group): GroupsTypes.ISetGroupAction {
  return {
    type: GroupsTypes.SET_GROUP,
    payload: {
      group,
    },
  };
}

export function clearGroup(group: GroupsTypes.Group): GroupsTypes.IClearGroupAction {
  return {
    type: GroupsTypes.CLEAR_GROUP,
    payload: {
      group,
    },
  };
}

export function setGroupsListError(): GroupsTypes.ISetGroupsListErrorAction {
  return {
    type: GroupsTypes.SET_GROUPS_LIST_ERROR,
  };
}

export function createGroup(config: GroupsTypes.GroupConfig): GroupsTypes.ICreateGroupAction {
  return {
    type: GroupsTypes.CREATE_GROUP,
    payload: {
      config,
    },
    meta: {
      id: uuid.v4(),
    },
  };
}

export function updateGroup(group: GroupsTypes.Group): GroupsTypes.IUpdateGroupAction {
  return {
    type: GroupsTypes.UPDATE_GROUP,
    payload: {
      group,
    },
    meta: {
      id: uuid.v4(),
    },
  };
}

export function deleteGroup(group: GroupsTypes.Group): GroupsTypes.IDeleteGroupAction {
  return {
    type: GroupsTypes.DELETE_GROUP,
    payload: {
      group,
    },
    meta: {
      id: uuid.v4(),
    },
  };
}

export function completeGroupOperation(id: string): GroupsTypes.ICompleteGroupOperationAction {
  return {
    type: GroupsTypes.COMPLETE_GROUP_OPERATION,
    meta: {
      id,
    },
  };
}
