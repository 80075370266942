import * as React from 'react';
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, makeStyles } from '@material-ui/core';

import { Group } from 'types/groups';
import { Machine } from 'types/machines';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '42vw',
    minWidth: '240px',
  },
  actions: {
    padding: theme.spacing(3),
  },
  autocomplete: {
    width: '100%',
  },
}));

interface Props {
  groupId: string;
  groups: Array<Group>;
  machines: Array<Machine>;
  onClose: () => void;
  onConfirm: (groupToAssign: string) => void;
}

export default function AssignMachineDialogView({ groupId, groups, machines, onClose, onConfirm }: Props) {
  const classes = useStyles();
  const [group, setGroup] = React.useState<Group | null>(null);
  const open = Boolean(machines.length > 0);

  React.useEffect(() => {
    const initial = groups.find((item) => item.id === groupId);
    setGroup(initial ?? null);
  }, [groups, groupId, setGroup]);

  React.useEffect(() => {
    if (!open) {
      setGroup(null);
    }
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      keepMounted
      open
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <span>Assign machine(s) {machines.map(({ config }) => `'${config.alias}'`).join(', ')}</span>
      </DialogTitle>

      <DialogContent>
        <Autocomplete
          className={classes.autocomplete}
          id="group-tag"
          fullWidth
          filterSelectedOptions
          value={group}
          onChange={(event, newValue) => setGroup(Array.isArray(newValue) ? newValue[0] : newValue)}
          options={groups}
          getOptionLabel={(option) => option.config.alias}
          renderInput={(params) => (
            <TextField {...params} fullWidth margin="dense" label="Group" variant="outlined" placeholder="Group" />
          )}
        />
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={groupId === group?.id}
          onClick={() => onConfirm(group?.id ?? '')}
          variant="contained"
          color="primary"
        >
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
}
