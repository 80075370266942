import * as React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'reducers';
import { Operation } from 'types/operation';

import OperationsPortalView from './OperationsPortalView';

export default function OperationsPortal() {
  const operations: Array<Operation> = useSelector((state: RootState) => [
    ...state.assetsState,
    ...state.machinesState,
    ...state.groupsState,
  ]);

  return <OperationsPortalView operations={operations} />;
}
