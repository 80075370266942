export const FETCH_ASSETS_LIST = 'assets/fetchList';
export const SET_ASSETS_LIST = 'assets/setList';
export const SET_ASSETS_LIST_ERROR = 'assets/setListError';
export const UPLOAD_ASSET = 'assets/uploadAsset';
export const SET_ASSET_UPLOAD_PROGRESS = 'assets/setUploadProgress';
export const FINISH_ASSET_UPLOAD = 'assets/finishAssetUpload';

export interface Asset {
  id: string;
  app: string;
  version: unknown;
}

export interface IFetchAssetsListAction {
  type: typeof FETCH_ASSETS_LIST;
}

export type ISetAssetsListPayload = Array<Asset>;

export interface ISetAssetsListAction {
  type: typeof SET_ASSETS_LIST;
  payload: ISetAssetsListPayload;
}

export interface ISetAssetsListErrorAction {
  type: typeof SET_ASSETS_LIST_ERROR;
}

export interface IUploadAssetPayload {
  file: File;
  name: string;
  version: string;
}

export interface IUploadAssetAction {
  type: typeof UPLOAD_ASSET;
  payload: IUploadAssetPayload;
}

export interface ISetAssetUploadProgressPayload {
  name: string;
  progress: number;
}

export interface ISetAssetUploadProgressAction {
  type: typeof SET_ASSET_UPLOAD_PROGRESS;
  payload: ISetAssetUploadProgressPayload;
}

export interface IFinishAssetUploadPayload {
  name: string;
}

export interface IFinishAssetUploadAction {
  type: typeof FINISH_ASSET_UPLOAD;
  payload: IFinishAssetUploadPayload;
}

export type IAssetsActionTypes =
  | IFetchAssetsListAction
  | ISetAssetsListAction
  | ISetAssetsListErrorAction
  | IUploadAssetAction
  | ISetAssetUploadProgressAction
  | IFinishAssetUploadAction;
