import superagent from 'superagent';
import { Group, GroupConfig } from 'types/groups';

import { headers, URL } from './constants';

const combineUrl = (...args: Array<string>) => [URL, 'groups', ...args].join('/');

const getGroups = async (token: string): Promise<any> => superagent.get(combineUrl()).set(headers.FirebaseAuth, token);

const createGroup = async (config: GroupConfig, token: string): Promise<any> =>
  superagent.post(combineUrl()).set(headers.FirebaseAuth, token).send({ config });

const updateGroup = async ({ id, config }: Group, token: string): Promise<any> =>
  superagent.put(combineUrl(id)).set(headers.FirebaseAuth, token).send({ config });

const deleteGroup = async ({ id }: Group, token: string): Promise<any> =>
  superagent.delete(combineUrl(id)).set(headers.FirebaseAuth, token);

export default {
  getGroups,
  createGroup,
  updateGroup,
  deleteGroup,
};
