import { combineReducers } from 'redux';

import AssetsReducer from './assets';
import AssetStateReducer from './assetsState';
import GroupsReducer from './groups';
import GroupsStateReducer from './groupsState';
import MachinesReducer from './machines';
import MachinesStateReducer from './machinesState';
import NotificationReducer from './notification';
import TopBarTitleReducer from './topBarTitle';

export interface RootState {
  assets: any;
  assetsState: any;
  groups: any;
  groupsState: any;
  machines: any;
  machinesState: any;
  notification: any;
  topBarTitle: any;
}

const rootReducer = combineReducers({
  assets: AssetsReducer,
  assetsState: AssetStateReducer,
  groups: GroupsReducer,
  groupsState: GroupsStateReducer,
  machines: MachinesReducer,
  machinesState: MachinesStateReducer,
  notification: NotificationReducer,
  topBarTitle: TopBarTitleReducer,
});

export default rootReducer;
