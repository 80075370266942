import * as React from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      '& > *': {
        margin: theme.spacing(1),
      },
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'start',
      width: '100%',
    },
  })
);

interface Props {
  children: React.ReactNode;
}

export default function GridToolbar({ children }: Props) {
  const classes = useStyles();

  return <Box className={classes.container}>{children}</Box>;
}
