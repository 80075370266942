import * as React from 'react';
import { useDispatch } from 'react-redux';

import { setTitle } from 'actions/topBarTitle';

export default function useTopBarTitleActions() {
  const dispatch = useDispatch();

  const [actions] = React.useState({
    setTitle: (title: string) => dispatch(setTitle(title)),
  });

  return actions;
}
