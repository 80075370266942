import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { CANCEL } from 'redux-saga';

import * as MachinesTypes from 'types/machines';
import {
  setMachinesList,
  setMachinesListError,
  completeMachineOperation,
  setMachineListMachine,
  addMachineListMachine,
  removeMachineListMachine,
} from 'actions/machines';
import api from 'api';
import { getAuthToken, getPayload } from 'api/utils';
import { addNotification } from 'actions/notification';
import { fetchGroupsList } from 'actions/groups';

export function* fetchMachinesList() {
  try {
    const token: String = yield getAuthToken();
    // @ts-ignore
    const request = yield call(api.machines.getMachines, token);
    request[CANCEL] = () => request.abort();

    const payload = getPayload(yield request);

    yield put(setMachinesList(payload));
  } catch (error) {
    yield put(addNotification('Failed to fetch machines configuration.', 'error'));
    yield put(setMachinesListError());
  }
}

export function* createMachine({ payload }: MachinesTypes.ICreateMachineAction) {
  const { machine } = payload;
  const { id, config } = machine;
  const { alias } = config;

  try {
    const token: String = yield getAuthToken();
    // @ts-ignore
    const request = yield call(api.machines.createMachine, id, config, token);
    yield request;

    yield put(addMachineListMachine(machine));
    if (machine.config.group.length > 0) {
      yield put(fetchGroupsList());
    }

    yield put(addNotification(`${alias} successfully created.`, 'success'));
  } catch (error) {
    yield put(addNotification(`Failed to create ${alias}!`, 'error'));
  }

  yield put(completeMachineOperation(machine));
}

export function* updateMachine({ payload }: MachinesTypes.IUpdateMachineAction) {
  const { machine } = payload;
  const { id, config } = machine;
  const { alias } = config;

  try {
    const token: String = yield getAuthToken();
    // @ts-ignore
    const request = yield call(api.machines.updateMachine, id, config, token);
    yield request;

    yield put(addNotification(`${alias} successfully updated.`, 'success'));
    yield put(setMachineListMachine(machine));
    yield put(fetchGroupsList());
  } catch (error) {
    yield put(addNotification(`Failed to update ${alias}!`, 'error'));
  }

  yield put(completeMachineOperation(machine));
}

export function* deleteMachine({ payload }: MachinesTypes.IDeleteMachineAction) {
  const { machine } = payload;
  const { id, config } = machine;
  const { alias } = config;

  try {
    const token: String = yield getAuthToken();
    // @ts-ignore
    const request = yield call(api.machines.deleteMachine, id, token);
    yield request;

    yield put(addNotification(`${alias} successfully deleted.`, 'success'));
    yield put(removeMachineListMachine(machine));
    if (machine.config.group.length > 0) {
      yield put(fetchGroupsList());
    }
  } catch (error) {
    yield put(addNotification(`Failed to delete ${alias}!`, 'error'));
  }

  yield put(completeMachineOperation(machine));
}

export default function* getMachinesWatcherSaga() {
  return all([
    // @ts-ignore
    yield takeLatest(MachinesTypes.FETCH_MACHINES_LIST, fetchMachinesList),
    // @ts-ignore
    yield takeEvery(MachinesTypes.CREATE_MACHINE, createMachine),
    // @ts-ignore
    yield takeEvery(MachinesTypes.UPDATE_MACHINE, updateMachine),
    // @ts-ignore
    yield takeEvery(MachinesTypes.DELETE_MACHINE, deleteMachine),
  ]);
}
