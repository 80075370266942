import * as React from 'react';
import { Link, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  copyright: {
    color: 'textSecondary',
  },
}));

export default function Copyright() {
  const classes = useStyles();

  return (
    <Typography className={classes.copyright} variant="body2" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://bmotion.technology/">
        Bmotion Technology
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
