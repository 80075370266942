import { RootState } from 'reducers';
import { Operation } from 'types/operation';

export default function groupsSelector(state: RootState) {
  const { isLoading, error, data } = state.groups;
  const { groupsState } = state;

  return {
    isLoading,
    error,
    groups: data.map((group: any) => ({
      ...group,
      actionInProgress: Boolean(groupsState.find(({ meta }: Operation) => meta.id === group.id)),
    })),
  };
}
