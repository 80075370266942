import * as Notification from 'types/notification';

export default function NotificationReducer(
  state: Array<Notification.IAddNotificationPayload> = [],
  action: Notification.PayloadType
) {
  switch (action.type) {
    case Notification.ADD_NOTIFICATION: {
      return state.concat(action.payload);
    }

    case Notification.REMOVE_NOTIFICATION: {
      const { id } = action.payload;
      return state.filter((item) => item.id !== id);
    }

    default:
      return state;
  }
}
