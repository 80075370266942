import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { Typography, IconButton, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { CloudUpload } from '@material-ui/icons';

import SelectedAsset from './SelectedAsset';

const useStyles = makeStyles(() => ({
  dropzone: {
    width: '400px',
    height: '200px',
    border: '3px',
    borderColor: grey[400],
    borderStyle: 'dashed',
    backgroundColor: grey[100],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
}));

export type AssetFile = File | null;

export interface Props {
  asset: AssetFile;
  disabled?: boolean;
  onAssetChange: (asset: AssetFile) => void;
}

export default function AssetSelect({ asset, disabled = false, onAssetChange }: Props) {
  const classes = useStyles();

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (files) => onAssetChange(files[0]),
    noClick: true,
    noKeyboard: true,
  });

  return (
    <Box pt={1}>
      {asset && <SelectedAsset file={asset} disabled={disabled} onRemove={() => onAssetChange(null)} />}

      {!asset && (
        <div {...getRootProps({ className: 'dropzone' })} className={classes.dropzone}>
          <input {...getInputProps()} />
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Drag 'n' drop asset file here
          </Typography>

          <IconButton onClick={open}>
            <CloudUpload />
          </IconButton>
        </div>
      )}
    </Box>
  );
}
