import * as MachinesTypes from 'types/machines';

interface MachineReducerState {
  isLoading: boolean;
  error: boolean;
  data: Array<MachinesTypes.Machine>;
}

const initialState = {
  isLoading: false,
  error: false,
  data: [],
};

export default function MachinesReducer(
  state: MachineReducerState = initialState,
  action: MachinesTypes.IMachinesActionTypes
) {
  switch (action.type) {
    case MachinesTypes.FETCH_MACHINES_LIST: {
      return {
        isLoading: true,
        error: false,
        data: [],
      };
    }

    case MachinesTypes.SET_MACHINES_LIST: {
      return {
        isLoading: false,
        error: false,
        data: action.payload,
      };
    }

    case MachinesTypes.SET_MACHINES_LIST_ERROR: {
      return {
        isLoading: false,
        error: true,
        data: [],
      };
    }

    case MachinesTypes.ADD_MACHINES_LIST_MACHINE: {
      const { machine } = action.payload;
      return {
        ...state,
        data: [machine, ...state.data],
      };
    }

    case MachinesTypes.SET_MACHINES_LIST_MACHINE: {
      const { machine } = action.payload;
      return {
        ...state,
        data: state.data.map((item) => (item.id === machine.id ? machine : item)),
      };
    }

    case MachinesTypes.REMOVE_MACHINES_LIST_MACHINE: {
      const { machine } = action.payload;
      return {
        ...state,
        data: state.data.filter((item) => item.id !== machine.id),
      };
    }

    default:
      return state;
  }
}
