import * as React from 'react';
import clsx from 'clsx';
import { ColDef, ValueFormatterParams, CellClassParams } from '@material-ui/data-grid';

import { Group } from 'types/groups';
import { DashboardPages } from 'routes';
import ProgressLabelCell from 'components/grid/ProgressLabelCell';

function buildCellClassParams(params: CellClassParams) {
  return clsx('styled-grid', {
    inProgress: params.row.actionInProgress,
  });
}

const getColumnDefinitions = (): Array<ColDef> => {
  return [
    {
      field: 'alias',
      headerName: 'Name',
      cellClassName: buildCellClassParams,
      renderCell: (params: ValueFormatterParams) => {
        const group = params.row as Group;
        const { actionInProgress } = params.row;

        return (
          <ProgressLabelCell
            inProgress={actionInProgress}
            value={group.config.alias}
            linkTo={`${DashboardPages.GROUPS}/${group.id}`}
          />
        );
      },
      flex: 2,
    },
    {
      field: 'machines',
      disableColumnMenu: true,
      headerName: 'Machines',
      cellClassName: buildCellClassParams,
      sortable: false,
      renderCell: (params: ValueFormatterParams) => {
        const group = params.row as Group;

        return <span>{group.config.machines.length} Machine(s)</span>;
      },
      flex: 2,
    },
  ];
};

export default getColumnDefinitions;
