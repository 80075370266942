import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { removeNotification } from 'actions/notification';
import { RootState } from 'reducers';

export default function Notification() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const notification = useSelector((state: RootState) => {
    const { notification } = state;
    return notification.length > 0 ? notification[0] : null;
  });

  useEffect(() => {
    if (!notification) return;

    const { id, message, variant } = notification;

    enqueueSnackbar(message, { variant });
    dispatch(removeNotification(id));
  }, [notification, enqueueSnackbar, dispatch]);

  return null;
}
