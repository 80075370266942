import * as AssetsTypes from 'types/assets';
import { Operation, OperationType, OperationVariant } from 'types/operation';

export type AssetsStateReducerState = Array<Operation>;

export default function AssetsStateReducer(
  state: AssetsStateReducerState = [],
  action: AssetsTypes.IAssetsActionTypes
) {
  switch (action.type) {
    case AssetsTypes.UPLOAD_ASSET: {
      const { name } = action.payload;
      return [
        ...state,
        {
          type: OperationType.Asset,
          id: name,
          progress: 0,
          operation: OperationVariant.Update,
        },
      ];
    }

    case AssetsTypes.SET_ASSET_UPLOAD_PROGRESS: {
      const { name, progress } = action.payload;

      return state.map((item) =>
        item.id === name
          ? {
              ...item,
              progress,
            }
          : item
      );
    }

    case AssetsTypes.FINISH_ASSET_UPLOAD: {
      const { name } = action.payload;
      return state.filter(({ id }) => id !== name);
    }

    default:
      return state;
  }
}
