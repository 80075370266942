import * as React from 'react';
import { useDispatch } from 'react-redux';

import { createGroup, deleteGroup, updateGroup, fetchGroupsList } from 'actions/groups';
import { Group, GroupConfig } from 'types/groups';

export default function useGroupsActions() {
  const dispatch = useDispatch();

  const [actions] = React.useState({
    fetch: () => dispatch(fetchGroupsList()),
    create: (config: GroupConfig) => dispatch(createGroup(config)),
    update: (group: Group) => dispatch(updateGroup(group)),
    delete: (group: Group) => dispatch(deleteGroup(group)),
  });

  return actions;
}
