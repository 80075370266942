import * as GroupsTypes from 'types/groups';
import { Group } from 'types/groups';

interface State {
  isLoading: boolean;
  error: boolean;
  data: Array<Group>;
}

const initialState = {
  isLoading: false,
  error: false,
  data: [],
};

export default function GroupsReducer(state: State = initialState, action: GroupsTypes.IGroupsActionTypes) {
  switch (action.type) {
    case GroupsTypes.FETCH_GROUPS_LIST: {
      return {
        isLoading: true,
        error: false,
        data: [],
      };
    }

    case GroupsTypes.SET_GROUPS_LIST: {
      return {
        isLoading: false,
        error: false,
        data: action.payload,
      };
    }

    case GroupsTypes.ADD_GROUP: {
      const { group } = action.payload;
      return {
        ...state,
        data: [group, ...state.data],
      };
    }

    case GroupsTypes.SET_GROUP: {
      const { group } = action.payload;
      return {
        ...state,
        data: state.data.map((item) => (item.id === group.id ? group : item)),
      };
    }

    case GroupsTypes.CLEAR_GROUP: {
      const { group } = action.payload;
      return {
        ...state,
        data: state.data.filter(({ id }) => id !== group.id),
      };
    }

    case GroupsTypes.SET_GROUPS_LIST_ERROR: {
      return {
        isLoading: false,
        error: true,
        data: [],
      };
    }

    default:
      return state;
  }
}
