import * as TopBarTitle from 'types/topBarTitle';

export default function TopBarTitleReducer(state: string = '', action: TopBarTitle.ITopBarActionTypes) {
  switch (action.type) {
    case TopBarTitle.SET_TOB_BAR_TITLE: {
      return action.payload.title;
    }

    default:
      return state;
  }
}
