import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { DataGrid, RowsProp, Columns, RowParams, SelectionChangeParams } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: '100%',
    '& .styled-grid.inProgress': {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[600],
    },
  },
}));

interface Props {
  loading: boolean;
  rows: RowsProp;
  columns: Columns;
  onRowClick?: (params: RowParams) => void;
  onSelectionChange?: (param: SelectionChangeParams) => void;
}

export default function StyledGrid({
  loading,
  rows,
  columns,
  onRowClick = undefined,
  onSelectionChange = undefined,
}: Props) {
  const classes = useStyles();

  return (
    <div className={classes.gridContainer}>
      <DataGrid
        checkboxSelection={Boolean(onSelectionChange)}
        autoPageSize
        disableSelectionOnClick
        density="compact"
        loading={loading}
        rows={rows}
        columns={columns}
        onRowClick={onRowClick}
        onSelectionChange={onSelectionChange}
      />
    </div>
  );
}
