import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, CircularProgress, Link, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export interface Props {
  inProgress: boolean;
  value: string;
  linkTo?: string;
}

export default function ProgressLabelCell({ linkTo = '', inProgress, value }: Props) {
  const classes = useStyles();
  const useLink = linkTo && !inProgress;

  return (
    <div className={classes.container}>
      {inProgress && (
        <>
          <CircularProgress size={12} />
          <Box pr={1} />
        </>
      )}
      {useLink && (
        <Link to={linkTo} component={RouterLink}>
          {value}
        </Link>
      )}
      {!useLink && <Typography variant="body2">{value}</Typography>}
    </div>
  );
}
