import * as React from 'react';
import _ from 'lodash';
import { CellParams, RowId, SelectionChangeParams } from '@material-ui/data-grid';
import { Box, CircularProgress } from '@material-ui/core';

import { Machine } from 'types/machines';
import StyledGrid from 'components/grid/StyledGrid';
import GridToolbar from 'components/grid/GridToolbar';
import GridToolbarButton from 'components/grid/GridToolbarButton';
import ConfigureMachineDialog from 'components/ConfigureMachineDialog';
import DeleteDialog from 'components/DeleteDialog';
import LayoutContainer from 'components/LayoutContainer';
import { getEmptyMachine } from 'utils/machines';
import AssignMachineDialog from 'components/AssignMachineDialog';
import StyledEditButton from 'components/grid/StyledEditButton';

import getColumnDefinitions from './columnDefinitions';

interface Props {
  isLoading: boolean;
  machines: Array<Machine>;
  groupId?: string;
  actions: any;
}

const getDeleteTitle = (machinesList: Array<RowId>, machines: Array<Machine>) => {
  return machinesList
    .map((item) => machines.find((machine) => machine.id === item))
    .filter(Boolean)
    .map((machine) => `'${machine!.config.alias}'`)
    .join(', ');
};

const getDeleteConfirmationText = (machinesList: Array<RowId>) => {
  if (machinesList.length === 1) return machinesList[0].toString();
  return 'DELETE MACHINES';
};

export default function MachinesView({ groupId = '', isLoading, machines, actions }: Props) {
  const [machineToEdit, setMachineToEdit] = React.useState<Machine | undefined>(undefined);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<Array<RowId>>([]);
  const [machinesToAssign, setMachinesToAssign] = React.useState<Array<Machine>>([]);

  const openDeleteDialog = () => setDeleteOpen(true);
  const closeDeleteDialog = () => setDeleteOpen(false);
  const openAssignDialog = () => setMachinesToAssign(machines.filter((item) => selectedRows.includes(item.id)));
  const closeAssignDialog = () => setMachinesToAssign([]);

  const actionsCellRenderer = (params: CellParams) => {
    const row = params.row as Machine;
    const { actionInProgress } = params.row;

    if (actionInProgress) {
      return <CircularProgress size={20} />;
    }

    return (
      <Box display="flex">
        <StyledEditButton disabled={actionInProgress} onClick={() => setMachineToEdit(row)} />
      </Box>
    );
  };

  const selectionActionsDisabled = selectedRows.length === 0;

  return (
    <LayoutContainer>
      <GridToolbar>
        <GridToolbarButton
          onClick={() => {
            const emptyMachine = getEmptyMachine();
            emptyMachine.config.group = groupId;
            setMachineToEdit(emptyMachine);
          }}
        >
          Create
        </GridToolbarButton>
        <GridToolbarButton disabled={selectionActionsDisabled} onClick={openAssignDialog}>
          Assign
        </GridToolbarButton>
        <GridToolbarButton disabled={selectionActionsDisabled} onClick={openDeleteDialog}>
          Delete
        </GridToolbarButton>
      </GridToolbar>

      <StyledGrid
        loading={isLoading}
        rows={machines}
        columns={getColumnDefinitions(actionsCellRenderer)}
        onSelectionChange={({ rowIds }: SelectionChangeParams) => setSelectedRows(rowIds)}
      />

      <ConfigureMachineDialog
        machine={machineToEdit}
        onConfirm={(machine: Machine, exists: boolean) => {
          setMachineToEdit(undefined);
          if (exists) {
            actions.update(machine);
          } else {
            actions.create(machine);
          }
        }}
        onClose={() => setMachineToEdit(undefined)}
      />

      <AssignMachineDialog
        groupId={groupId}
        machines={machinesToAssign}
        onClose={closeAssignDialog}
        onConfirm={(groupToAssign: string) => {
          closeAssignDialog();
          setSelectedRows([]);

          selectedRows.forEach((machineId) => {
            const machineToUpdate = _.cloneDeep(machines.find(({ id }) => machineId === id));
            if (machineToUpdate) {
              machineToUpdate.config.group = groupToAssign;
              actions.update(machineToUpdate);
            }
          });
        }}
      />

      <DeleteDialog
        open={deleteOpen}
        titleText={getDeleteTitle(selectedRows, machines)}
        typeText={`machine${selectedRows.length > 1 ? 's' : ''}`}
        confirmationText={getDeleteConfirmationText(selectedRows)}
        onClose={closeDeleteDialog}
        onConfirm={() => {
          closeDeleteDialog();
          setSelectedRows([]);

          selectedRows.forEach((machineId) => {
            const machineToDelete = machines.find(({ id }) => machineId === id);
            if (machineToDelete) {
              actions.delete(machineToDelete);
            }
          });
        }}
      />
    </LayoutContainer>
  );
}
