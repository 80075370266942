import * as React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  withStyles,
  makeStyles,
  Portal,
  Typography,
  IconButton,
  LinearProgress,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { Close, ExpandMore } from '@material-ui/icons';

import { Operation, OperationType } from 'types/operation';

const useStyles = makeStyles((theme) => ({
  modal: {
    maxWidth: '500px',
    backgroundColor: 'white',
    position: 'fixed',
    zIndex: 5,
    display: 'flex',
    flexDirection: 'column',
    bottom: 0,
    right: theme.spacing(3),
  },
  header: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    flex: 1,
  },
  closeButton: {
    color: theme.palette.primary.contrastText,
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    paddingBottom: 0,
    paddingRight: 0,
  },
  expandButton: {
    color: theme.palette.primary.contrastText,
  },
  progressBarContainer: {
    flex: 1,
    width: '100%',
    minWidth: '120px',
  },
  tableContainer: {
    maxHeight: 440,
  },
  progressText: {
    minWidth: 40,
  },
}));

const StyledLinearProgress = withStyles(() => ({
  root: {
    height: 10,
    width: '100%',
    borderRadius: 2,
  },
  bar: {
    borderRadius: 2,
  },
}))(LinearProgress);

const StyledTableCell = withStyles((theme) => ({
  root: {
    borderBottom: 'none',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}))(TableCell);

export interface Props {
  operations: Array<Operation>;
}

export default function OperationsPortalView({ operations }: Props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    setVisible(operations.length > 0);
  }, [operations, setOpen, setVisible]);

  return (
    <Portal container={document.getElementById('root')}>
      <Slide direction="up" in={visible}>
        <div className={classes.modal}>
          <Accordion expanded={open && operations.length > 0} onChange={() => setOpen((value) => !value)}>
            <AccordionSummary className={classes.header} expandIcon={<ExpandMore className={classes.expandButton} />}>
              <Typography className={classes.title}>{operations.length} operations in progress</Typography>
              <IconButton className={classes.closeButton} onClick={() => setVisible(false)}>
                <Close />
              </IconButton>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer className={classes.tableContainer}>
                <Table size="small">
                  <TableBody>
                    {operations.map((item) => {
                      const { progress, id, operation, type, meta } = item;
                      const progressText = progress !== null ? `${Math.round(progress)}%` : '';
                      const displayId = type === OperationType.Group ? meta.config.alias : id;
                      return (
                        <TableRow tabIndex={-1} key={`${operation}-${type}-${id}`}>
                          <StyledTableCell>
                            {operation} {type} - {displayId}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box className={classes.progressBarContainer}>
                              {progress !== null && <StyledLinearProgress variant="determinate" value={progress} />}
                              {progress === null && <StyledLinearProgress />}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Typography className={classes.progressText} variant="body2">
                              {progressText}
                            </Typography>
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </div>
      </Slide>
    </Portal>
  );
}
