import { withStyles } from '@material-ui/core';

export const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    '.MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
  },
})(() => null);
