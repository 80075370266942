import { all, call, put, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { CANCEL } from 'redux-saga';

import * as AssetsTypes from 'types/assets';

import api from 'api';
import { getAuthToken, getPayload } from 'api/utils';
import { setAssetsList, setAssetsListError, setUploadProgress, finishAssetUpload } from 'actions/assets';
import { addNotification } from 'actions/notification';

export function* fetchAssetsList() {
  try {
    const token: String = yield getAuthToken();
    // @ts-ignore
    const request = yield call(api.assets.getAssets, token);
    request[CANCEL] = () => request.abort();

    const payload = getPayload(yield request);

    const assetsList: Array<AssetsTypes.Asset> = Object.entries(payload.entities).map(([key, value]) => ({
      id: key,
      app: key,
      version: value,
    }));

    assetsList.sort((a, b) => a.app.localeCompare(b.app));

    yield put(setAssetsList(assetsList));
  } catch (error) {
    yield put(addNotification('Failed to fetch assets configuration.', 'error'));
    yield put(setAssetsListError());
  }
}

export function* uploadAsset({ payload }: AssetsTypes.IUploadAssetAction) {
  const { file, name, version } = payload;

  try {
    const token: String = yield getAuthToken();
    // @ts-ignore
    const uploadInfoRequest = yield call(api.assets.getUploadInfo, name, version, token);
    const payload = getPayload(yield uploadInfoRequest);
    const uploadVersion = payload.version;

    // @ts-ignore
    const channel = yield call(api.assets.getUploadEventChannel, payload.url, file!!);
    while (true) {
      const { progress, result, error } = yield take(channel);
      if (progress) {
        yield put(setUploadProgress(name, progress));
      }
      if (result) {
        break;
      }
      if (error) {
        throw new Error('Upload failed');
      }
    }

    // @ts-ignore
    const versionRequest = yield call(api.assets.setLatestVersion, name, uploadVersion, token);
    yield versionRequest;

    yield put(addNotification('Successfully created.', 'success'));
    yield put(finishAssetUpload(name));

    // TODO: update only this asset info
    yield call(fetchAssetsList);
  } catch (error) {
    yield put(addNotification('Failed to upload asset version.', 'error'));
    yield put(finishAssetUpload(name));
  }
}

export default function* getAssetsWatcherSaga() {
  return all([
    // @ts-ignore
    yield takeLatest(AssetsTypes.FETCH_ASSETS_LIST, fetchAssetsList),
    // @ts-ignore
    yield takeEvery(AssetsTypes.UPLOAD_ASSET, uploadAsset),
  ]);
}
