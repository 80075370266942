import superagent from 'superagent';
import { MachineConfig } from 'types/machines';

import { headers, URL } from './constants';

const url = `${URL}/machines`;

const getMachines = async (token: string): Promise<any> => superagent.get(url).set(headers.FirebaseAuth, token);

const createMachine = async (id: string, machineConfig: MachineConfig | null, token: string): Promise<any> =>
  superagent.post(url).set(headers.FirebaseAuth, token).set(headers.MachineId, id).send({ machineConfig });

const updateMachine = async (id: string, machineConfig: MachineConfig, token: string): Promise<any> =>
  superagent.put(url).set(headers.FirebaseAuth, token).set(headers.MachineId, id).send({ machineConfig });

const deleteMachine = async (id: string, token: string): Promise<any> =>
  superagent.delete(url).set(headers.FirebaseAuth, token).set(headers.MachineId, id);

export default {
  getMachines,
  createMachine,
  updateMachine,
  deleteMachine,
};
