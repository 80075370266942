import * as GroupsTypes from 'types/groups';
import { Operation, OperationType, OperationVariant } from 'types/operation';

export type GroupsStateReducerState = Array<Operation>;

export default function GroupsStateReducer(
  state: GroupsStateReducerState = [],
  action: GroupsTypes.IGroupsActionTypes
) {
  switch (action.type) {
    case GroupsTypes.CREATE_GROUP: {
      const { meta, payload } = action;
      return [
        ...state,
        {
          type: OperationType.Group,
          operation: OperationVariant.Create,
          id: meta.id,
          progress: null,
          meta: {
            id: '',
            config: payload.config,
          },
        },
      ];
    }
    case GroupsTypes.UPDATE_GROUP: {
      const { meta, payload } = action;
      return [
        ...state,
        {
          type: OperationType.Group,
          operation: OperationVariant.Update,
          id: meta.id,
          progress: null,
          meta: payload.group,
        },
      ];
    }
    case GroupsTypes.DELETE_GROUP: {
      const { meta, payload } = action;
      return [
        ...state,
        {
          type: OperationType.Group,
          operation: OperationVariant.Delete,
          id: meta.id,
          progress: null,
          meta: payload.group,
        },
      ];
    }
    case GroupsTypes.COMPLETE_GROUP_OPERATION: {
      const { meta } = action;
      return state.filter(({ id }) => id !== meta.id);
    }
    default:
      return state;
  }
}
