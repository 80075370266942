import * as React from 'react';
import filesize from 'filesize';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Description, Delete } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingLeft: '0',
    marginRight: theme.spacing(2),
  },
}));

export interface Props {
  file: File;
  disabled?: boolean;
  onRemove: () => void;
}

export default function SelectedAsset({ file, disabled = false, onRemove }: Props) {
  const classes = useStyles();

  return (
    <List dense>
      <ListItem className={classes.listItem}>
        <ListItemAvatar>
          <Avatar>
            <Description />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={file.name} secondary={filesize(file.size)} />
        <ListItemSecondaryAction>
          <IconButton disabled={disabled} edge="end" aria-label="delete" onClick={onRemove}>
            <Delete />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}
