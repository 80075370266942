import * as React from 'react';
import { useSelector } from 'react-redux';

import { GroupConfig } from 'types/groups';
import unassignedMachinesSelector from 'selectors/selectUnassignedMachines';

import CreateGroupDialogView from './CreateGroupDialogView';

export interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (config: GroupConfig) => void;
}

export default function CreateGroupDialog({ open, onClose, onConfirm }: Props) {
  const { machines } = useSelector(unassignedMachinesSelector);

  return <CreateGroupDialogView open={open} machines={machines} onClose={onClose} onConfirm={onConfirm} />;
}
