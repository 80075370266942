import * as React from 'react';
import {
  TextField,
  Checkbox,
  Menu,
  MenuItem,
  IconButton,
  Box,
  FormGroup,
  FormControlLabel,
  Collapse,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDownCircle } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  menuItem: {
    minWidth: '120px',
  },
  version: {
    display: 'flex',
  },
}));

export interface Props {
  version: string;
  disabled?: boolean;
  isNew: boolean;
  onVersionChange: (version: string) => void;
  versionOptions: Array<string>;
}

const autoGenerateLabel = 'Auto generate asset version';

export default function VersionSelect({ onVersionChange, disabled = false, isNew, version, versionOptions }: Props) {
  const classes = useStyles();
  const [autoVersion, setAutoVersion] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  React.useEffect(() => {
    if (autoVersion) {
      onVersionChange('');
    }
  }, [autoVersion, onVersionChange]);

  const handleOpenDropdown = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleVersionChange = (event: React.ChangeEvent<HTMLInputElement>) => onVersionChange(event.target.value);
  const handleAutoVersionChange = () => setAutoVersion((checked) => !checked);
  const handleMenuItemClick = (selectedVersion: string) => {
    onVersionChange(selectedVersion);
    setAnchorEl(null);
  };
  const handleClose = () => setAnchorEl(null);

  return (
    <React.Fragment>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={autoVersion}
              onChange={handleAutoVersionChange}
              color="primary"
              inputProps={{
                'aria-label': autoGenerateLabel,
              }}
            />
          }
          label={autoGenerateLabel}
        />
      </FormGroup>

      <Collapse in={!autoVersion}>
        <Box className={classes.version}>
          <TextField
            autoFocus
            autoComplete="off"
            margin="dense"
            id="version"
            label="Version"
            type="text"
            variant="outlined"
            value={version}
            disabled={disabled}
            onChange={handleVersionChange}
            fullWidth
          />
          {!isNew && (
            <IconButton onClick={handleOpenDropdown} disabled={disabled}>
              <ArrowDropDownCircle />
            </IconButton>
          )}
        </Box>
      </Collapse>

      <Menu id="lock-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {versionOptions.map((option) => (
          <MenuItem className={classes.menuItem} key={option} onClick={() => handleMenuItemClick(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
