import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as firebase from 'firebase/app';
import dotenv from 'dotenv';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import App from 'components/App';
import Notification from 'components/Notification';
import { GlobalCss } from 'styles/global';
import store from 'store';

import './index.css';

dotenv.config();

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
});

const rootElement = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <GlobalCss />
    <Router>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <App />
        <Notification />
      </SnackbarProvider>
    </Router>
  </Provider>,
  rootElement
);
