import * as React from 'react';
import { Box, Divider, List, ListItem, TextField, Paper, Popover, makeStyles } from '@material-ui/core';

import { Asset } from 'types/assets';

export interface Props {
  anchorEl: null | HTMLElement;
  assets: Array<Asset>;
  onClose: () => void;
  onSelect: (asset: string) => void;
}

const useStyles = makeStyles(() => ({
  menuList: {
    maxHeight: '320px',
    overflow: 'auto',
  },
}));

export default function AssetsListMenu({ anchorEl, assets, onClose, onSelect }: Props) {
  const classes = useStyles();
  const [filterText, setFilterText] = React.useState('');
  const assetsApps = React.useMemo(() => assets.map(({ app }) => app), [assets]);
  const assetsList = React.useMemo(() => {
    if (filterText.length === 0) return assetsApps;

    const criteria = filterText.toLowerCase();
    return assetsApps.filter((item) => item.toLowerCase().includes(criteria));
  }, [assetsApps, filterText]);

  if (!anchorEl) return null;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => setFilterText(event.target.value);

  return (
    <Popover
      open
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={() => {
        onClose();
        setFilterText('');
      }}
    >
      <Box p={1}>
        <TextField
          autoFocus
          variant="outlined"
          fullWidth
          margin="dense"
          id="search"
          label="Search"
          type="text"
          InputProps={{
            autoComplete: 'off',
            autoCorrect: 'off',
          }}
          value={filterText}
          onChange={handleChange}
        />
      </Box>

      <Divider />

      <Paper className={classes.menuList}>
        <List>
          {assetsList.map((asset) => (
            <ListItem
              button
              key={asset}
              onClick={() => {
                onSelect(asset);
                setFilterText('');
              }}
            >
              {asset}
            </ListItem>
          ))}
        </List>
      </Paper>
    </Popover>
  );
}
