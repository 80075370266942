import * as React from 'react';
import { useSelector } from 'react-redux';

import groupsSelector from 'selectors/groupsSelector';
import { Machine } from 'types/machines';

import AssignMachineDialogView from './AssignMachineDialogView';

interface Props {
  groupId: string;
  machines: Array<Machine>;
  onClose: () => void;
  onConfirm: (groupToAssign: string) => void;
}

export default function AssignMachineDialog(props: Props) {
  const { groups } = useSelector(groupsSelector);

  return <AssignMachineDialogView groups={groups} {...props} />;
}
