import * as MachinesTypes from 'types/machines';

export function fetchMachinesList(): MachinesTypes.IFetchMachinesListAction {
  return {
    type: MachinesTypes.FETCH_MACHINES_LIST,
  };
}

export function setMachinesList(Machines: Array<MachinesTypes.Machine>): MachinesTypes.ISetMachinesListAction {
  return {
    type: MachinesTypes.SET_MACHINES_LIST,
    payload: Machines,
  };
}

export function setMachinesListError(): MachinesTypes.ISetMachinesListErrorAction {
  return {
    type: MachinesTypes.SET_MACHINES_LIST_ERROR,
  };
}

export function addMachineListMachine(machine: MachinesTypes.Machine): MachinesTypes.IAddMachineListMachineAction {
  return {
    type: MachinesTypes.ADD_MACHINES_LIST_MACHINE,
    payload: {
      machine,
    },
  };
}

export function setMachineListMachine(machine: MachinesTypes.Machine): MachinesTypes.ISetMachineListMachineAction {
  return {
    type: MachinesTypes.SET_MACHINES_LIST_MACHINE,
    payload: {
      machine,
    },
  };
}

export function removeMachineListMachine(
  machine: MachinesTypes.Machine
): MachinesTypes.IRemoveMachineListMachineAction {
  return {
    type: MachinesTypes.REMOVE_MACHINES_LIST_MACHINE,
    payload: {
      machine,
    },
  };
}

export function createMachine(machine: MachinesTypes.Machine): MachinesTypes.ICreateMachineAction {
  return {
    type: MachinesTypes.CREATE_MACHINE,
    payload: {
      machine,
    },
  };
}

export function updateMachine(machine: MachinesTypes.Machine): MachinesTypes.IUpdateMachineAction {
  return {
    type: MachinesTypes.UPDATE_MACHINE,
    payload: {
      machine,
    },
  };
}

export function deleteMachine(machine: MachinesTypes.Machine): MachinesTypes.IDeleteMachineAction {
  return {
    type: MachinesTypes.DELETE_MACHINE,
    payload: {
      machine,
    },
  };
}

export function completeMachineOperation(
  machine: MachinesTypes.Machine
): MachinesTypes.ICompleteMachineOperationAction {
  return {
    type: MachinesTypes.COMPLETE_MACHINE_OPERATION,
    payload: {
      machine,
    },
  };
}
