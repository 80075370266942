import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, CssBaseline } from '@material-ui/core';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import TopBar from 'components/TopBar';
import SideBar from 'components/SideBar';
import Copyright from 'components/Copyright';
import Assets from 'components/Assets';
import Machines from 'components/Machines';
import ConfigureGroup from 'components/ConfigureGroup';
import Groups from 'components/Groups';
import OperationsPortal from 'components/OperationsPortal';
import { withAuthorization } from 'components/Session';
import { Pages, DashboardPages } from 'routes';
import ErrorFallback from 'components/ErrorFallback';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100vh',
  },
  container: {
    height: '100%',
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = React.useCallback(() => {
    setOpen((state) => !state);
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopBar expanded={open} toggleExpansion={toggleDrawer} />
      <SideBar open={open} toggleOpen={toggleDrawer} />
      <OperationsPortal />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container className={classes.container} maxWidth={false}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              // reset the state of your app so the error doesn't happen again
            }}
          >
            <Switch>
              <Route exact path={Pages.DASHBOARD}>
                <Redirect to={DashboardPages.ASSETS} />
              </Route>
              <Route path={DashboardPages.ASSETS}>
                <Assets />
              </Route>
              <Route path={DashboardPages.MACHINES}>
                <Machines />
              </Route>
              <Route path={`${DashboardPages.GROUPS}/:id`}>
                <ConfigureGroup />
              </Route>
              <Route path={DashboardPages.GROUPS}>
                <Groups />
              </Route>
            </Switch>
          </ErrorBoundary>
        </Container>
        <Box pt={1} pb={1}>
          <Copyright />
        </Box>
      </main>
    </div>
  );
}

export default withAuthorization(Dashboard);
