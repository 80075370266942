export const ADD_NOTIFICATION = 'notification/add';
export const REMOVE_NOTIFICATION = 'notification/remove';

export interface IAddNotificationPayload {
  id: string;
  message: string;
  variant: string;
}

export interface IAddNotificationAction {
  type: typeof ADD_NOTIFICATION;
  payload: IAddNotificationPayload;
}

export interface IRemoveNotificationPayload {
  id: string;
}

export interface IRemoveNotificationAction {
  type: typeof REMOVE_NOTIFICATION;
  payload: IRemoveNotificationPayload;
}

export type PayloadType = IAddNotificationAction | IRemoveNotificationAction;
