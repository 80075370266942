export enum OperationType {
  Asset = 'Asset',
  Machine = 'Machine',
  Group = 'Group',
}

export enum OperationVariant {
  Create = 'Create',
  Read = 'Read',
  Update = 'Update',
  Delete = 'Delete',
}

export interface Operation {
  type: OperationType;
  id: string;
  operation: OperationVariant;
  progress: number | null;
  meta?: any;
}
