export const Pages = {
  LOG_IN: '/login',
  DASHBOARD: '/dash',
};

const getDashboardRoute = (page: string) => `${Pages.DASHBOARD}/${page}`;

export const DashboardPages = {
  ASSETS: getDashboardRoute('assets'),
  MACHINES: getDashboardRoute('machines'),
  GROUPS: getDashboardRoute('groups'),
};
