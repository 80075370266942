import { RootState } from 'reducers';
import { Operation } from 'types/operation';

export default function machinesSelector(state: RootState) {
  const { isLoading, error, data } = state.machines;
  const { machinesState } = state;

  return {
    isLoading,
    error,
    machines: data.map((item: any) => ({
      ...item,
      actionInProgress: Boolean(machinesState.find(({ id }: Operation) => id === item.id)),
    })),
  };
}
