import * as React from 'react';
import { CircularProgress } from '@material-ui/core';
import { CellParams } from '@material-ui/data-grid';

import { Asset } from 'types/assets';
import StyledGrid from 'components/grid/StyledGrid';
import ConfigureAssetDialog from 'components/ConfigureAssetDialog';
import LayoutContainer from 'components/LayoutContainer';
import GridToolbar from 'components/grid/GridToolbar';
import GridToolbarButton from 'components/grid/GridToolbarButton';
import StyledEditButton from 'components/grid/StyledEditButton';

import getColumnDefinitions from './columnDefinitions';

interface ConfigureState {
  open: boolean;
  assetId: string | null;
}

interface Props {
  isLoading: boolean;
  assets: Array<Asset>;
}

const getInitialConfigureState = (): ConfigureState => ({
  open: false,
  assetId: null,
});

export default function AssetsView({ assets, isLoading }: Props) {
  const [configureState, setConfigureState] = React.useState<ConfigureState>(getInitialConfigureState());

  const resetConfigureState = () => setConfigureState(getInitialConfigureState());
  const addAsset = () =>
    setConfigureState({
      open: true,
      assetId: null,
    });

  const actionsCellRenderer = (params: CellParams) => {
    const asset = params.row as Asset;
    const { actionInProgress } = params.row;

    if (actionInProgress) {
      return <CircularProgress size={20} />;
    }

    return (
      <StyledEditButton
        onClick={() =>
          setConfigureState({
            open: true,
            assetId: asset.app,
          })
        }
      />
    );
  };

  return (
    <LayoutContainer>
      <GridToolbar>
        <GridToolbarButton onClick={addAsset}>Create</GridToolbarButton>
      </GridToolbar>

      <StyledGrid loading={isLoading} rows={assets} columns={getColumnDefinitions(actionsCellRenderer)} />

      {configureState.open && <ConfigureAssetDialog assetId={configureState.assetId} onClose={resetConfigureState} />}
    </LayoutContainer>
  );
}
