import { Machine, MachineProfile } from 'types/machines';

export const getEmptyMachine = (): Machine => ({
  id: '',
  config: {
    group: '',
    alias: '',
    entities: [],
    profile: MachineProfile.unknown,
  },
});
