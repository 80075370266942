import * as React from 'react';
import _ from 'lodash';

import api from 'api';
import { getAuthToken, getPayload } from 'api/utils';

type Versions = Array<string> | null;

export default function useAssetVersions(assetId: string | null): Versions {
  const [versionOptions, setVersionOptions] = React.useState<Versions>(null);

  React.useEffect(() => {
    let cancelled = false;

    if (_.isNil(assetId)) {
      setVersionOptions(null);
    } else if (assetId) {
      getAuthToken()
        .then((token) => {
          return api.assets.getVersions(assetId, token);
        })
        .then((payload) => {
          if (!cancelled) {
            setVersionOptions(getPayload(payload).versions);
          }
        })
        .catch(() => {});
    } else {
      setVersionOptions([]);
    }

    return () => {
      cancelled = true;
    };
  }, [assetId]);

  return versionOptions;
}
