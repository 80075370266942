import * as TopBarTitleTypes from 'types/topBarTitle';

export function setTitle(title: string): TopBarTitleTypes.ISetTopBarTitleAction {
  return {
    type: TopBarTitleTypes.SET_TOB_BAR_TITLE,
    payload: {
      title,
    },
  };
}
