import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography, makeStyles } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

import { DashboardPages } from 'routes';
import { Group } from 'types/groups';
import { Machine } from 'types/machines';
import LayoutContainer from 'components/LayoutContainer';
import PageLoading from 'components/PageLoading';
import MachinesGrid from 'components/MachinesGrid';

interface Props {
  isLoading: boolean;
  group: Group;
  machines: Array<Machine>;
  onBackClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  groupTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    columnGap: theme.spacing(2),
    alignItems: 'center',
  },
  navigationList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    columnGap: theme.spacing(2),
    alignItems: 'center',
  },
}));

export default function ConfigureGroupView({ isLoading, group, machines, onBackClick }: Props) {
  const classes = useStyles();

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <LayoutContainer>
      <Box pb={2} className={classes.navigationList}>
        <Link to={DashboardPages.GROUPS} component={RouterLink}>
          Groups
        </Link>
        <ChevronRight />
        <Typography>{group?.config.alias}</Typography>
      </Box>

      <MachinesGrid groupId={group?.id} machines={machines} isLoading={isLoading} />
    </LayoutContainer>
  );
}
