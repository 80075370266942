import * as React from 'react';
import { FallbackProps } from 'react-error-boundary';

function ErrorFallback(props: FallbackProps) {
  const { error } = props;

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error?.message}</pre>
    </div>
  );
}

export default ErrorFallback;
